import { UrlItem } from "@retailtune/types/lib/storelocator";
//TODO to ask if it's admitted to do so...

export interface ActiveLanguage {
  languageName: string;
  language: string;
  path: string;
}

export function createLanguagSidebarContent(
  languages: ActiveLanguage[],
  currentLanguage: string,
  urlPrefix: string
) {
  return (
    <div id={"rt_sidebar"} class="rt-languages">
      <header class="rt-languages__header">
        <span aria-label="current language">{`${currentLanguage.toUpperCase()}`}</span>
      </header>
      <div class="rt-languages__body">
        <ul class="rt-languages__list">
          {languages.map((lang) => (
            <li
              class={`rt-languages__item ${
                currentLanguage === lang.language
                  ? "rt-languages__item--current"
                  : ""
              }`}
              key={lang.language}
            >
              <a href={`${urlPrefix}/${lang.language}${lang.path}`}>
                {lang.languageName}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
